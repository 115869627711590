import _ from 'lodash'
import { combineReducers } from 'redux'
import { handleActions, combineActions } from 'redux-actions'
import { allById, allIds, customById, idsByCustom, receiveCollection, randomId } from 'lib/reducers'
import Actions from 'workspace/actions'
import navigation from 'containers/Navigation/reducer'
import { SET_NAVIGATION } from 'containers/Navigation/actions'
import { idsByCustomCollection, idsByTruth } from '../lib/reducers'

const OMIT_FOR_SUBFORM = [
  'scrapTypeId',
  'quantity',
  'quantityLeft',
  'quantityRight'
]

const config = handleActions(
  {
    [Actions.SET_TOKEN]: (state, { payload: token }) => ({ ...state, token }),
    [Actions.SET_MODE]: (state, { payload: mode }) => ({ ...state, mode })
  },
  { token: null, mode: null, locale: null, timezone: null }
)

export const init = handleActions(
  {
    [Actions.START_INIT]: state => ({ ...state, isFinished: false }),
    [Actions.FINISH_INIT]: state => ({ ...state, isFinished: true })
  },
  { isFinished: false }
)

export const locations = handleActions(
  {
    [Actions.SELECT_LOCATION]: ({ selectedIds, ...rest }, { payload: id }) => (
      {
        ...rest,
        selectedIds: _.uniq([...selectedIds, id])
      }
    ),
    [Actions.SELECT_SINGLE_LOCATION]: ({ selectedIds, ...rest }, { payload: id }) => (
      {
        ...rest,
        selectedIds: [id]
      }
    ),
    [Actions.UNSELECT_LOCATION]: ({ selectedIds, ...rest }, { payload: id }) => (
      {
        ...rest,
        selectedIds: _.without(selectedIds, id)
      }
    ),
    [Actions.RECEIVE_LOCATIONS]: (state, { payload: locations }) => ({
      ...state,
      allById: allById(locations),
      allIds: allIds(locations),
      selectedIds: [locations[0].id],
      isLoadingSchedulesById: customById(locations, false),
      isLoadingRealizationsById: customById(locations, false),
      isLoadingChangeoversById: customById(locations, false),
      isLoadingScrapsById: customById(locations, false)
    }),
    [Actions.REQUEST_SCHEDULES]: (state, { payload: locationId }) => ({
      ...state,
      isLoadingSchedulesById: {
        ...state.isLoadingSchedulesById,
        [locationId]: true
      }
    }),
    [Actions.RECEIVE_SCHEDULES]: (state, { payload: { locationId } }) => ({
      ...state,
      isLoadingSchedulesById: {
        ...state.isLoadingSchedulesById,
        [locationId]: false
      }
    }),
    [Actions.REQUEST_REALIZATIONS]: (state, { payload: locationId }) => ({
      ...state,
      isLoadingRealizationsById: {
        ...state.isLoadingRealizationsById,
        [locationId]: true
      }
    }),
    [Actions.RECEIVE_REALIZATIONS]: (state, { payload: { locationId } }) => ({
      ...state,
      isLoadingRealizationsById: {
        ...state.isLoadingRealizationsById,
        [locationId]: false
      }
    }),
    [Actions.REQUEST_CHANGEOVERS]: (state, { payload: locationId }) => ({
      ...state,
      isLoadingChangeoversById: {
        ...state.isLoadingChangeoversById,
        [locationId]: true
      }
    }),
    [Actions.RECEIVE_CHANGEOVERS]: (state, { payload: { locationId } }) => ({
      ...state,
      isLoadingChangeoversById: {
        ...state.isLoadingChangeoversById,
        [locationId]: false
      }
    }),
    [Actions.REQUEST_SCRAPS]: (state, { payload: locationId }) => ({
      ...state,
      isLoadingScrapsById: {
        ...state.isLoadingScrapsById,
        [locationId]: true
      }
    }),
    [Actions.RECEIVE_SCRAPS]: (state, { payload: { locationId } }) => ({
      ...state,
      isLoadingScrapsById: {
        ...state.isLoadingScrapsById,
        [locationId]: false
      }
    }),
    [Actions.REQUEST_REALIZATION_SUMMARIES]: (state, { payload: locationId }) => ({
      ...state,
      isLoadingRealizationSummariesById: {
        ...state.isLoadingRealizationSummariesById,
        [locationId]: true
      }
    }),
    [Actions.RECEIVE_REALIZATION_SUMMARIES]: (state, { payload: { locationId } }) => ({
      ...state,
      isLoadingRealizationSummariesById: {
        ...state.isLoadingRealizationSummariesById,
        [locationId]: false
      }
    }),
    [Actions.SHOW_ALL_LOCATIONS]: state => ({
      ...state,
      selectedIds: state.allIds
    }),
    [Actions.HIDE_ALL_LOCATIONS]: state => ({
      ...state,
      selectedIds: []
    })
  },
  {
    allById: {},
    allIds: [],
    selectedIds: [],
    isLoadingSchedulesById: {},
    isLoadingRealizationsById: {},
    isLoadingChangeoversById: {},
    isLoadingScrapsById: {},
    isLoadingRealizationSummariesById: {}
  }
)

const projects = handleActions(
  {
    [Actions.RECEIVE_PROJECTS]: (state, { payload: projects }) => ({
      allById: allById(projects),
      allIds: allIds(projects),
      activeIds: idsByTruth(projects, 'active')
    })
  },
  { allById: {}, allIds: [], activeIds: [] }
)

const products = handleActions(
  {
    [Actions.RECEIVE_PRODUCTS]: (state, { payload: products }) => ({
      allById: allById(products),
      allIds: allIds(products),
      activeIds: idsByTruth(products, 'active')
    })
  },
  { allById: {}, allIds: [], activeIds: [] }
)

const stoppageTypes = handleActions(
  {
    [Actions.RECEIVE_STOPPAGE_TYPES]: receiveCollection
  },
  { allById: {}, allIds: [] }
)

const scrapTypes = handleActions(
  {
    [Actions.RECEIVE_SCRAP_TYPES]: receiveCollection
  },
  { allById: {}, allIds: [] }
)

const schedules = handleActions(
  {
    [Actions.RECEIVE_LOCATIONS]: (state, { payload: locations }) => ({
      ...state,
      idsByLocation: customById(locations, []),
      planChangedByLocation: customById(locations, false)
    }),
    [Actions.RECEIVE_SCHEDULES]: (state, { payload: { locationId, schedules } }) => ({
      ...state,
      allById: {
        ...state.allById,
        ...allById(schedules)
      },
      idsByLocation: {
        ...state.idsByLocation,
        [locationId]: _.uniq(allIds(schedules))
      }
    }),
    [Actions.UPDATE_PLAN_CHANGED]: (state, { payload: planChanged }) => ({ ...state, planChanged }),
    [SET_NAVIGATION]: (state) => ({ ...state, planChanged: false }),
    [Actions.SELECT_SINGLE_LOCATION]: (state) => ({ ...state, planChanged: false }),
    [Actions.SELECT_LOCATION]: (state) => ({ ...state, planChanged: false })
  },
  {
    allById: {},
    idsByLocation: {},
    planChanged: false
  }
)

const realizations = handleActions(
  {
    [Actions.RECEIVE_LOCATIONS]: (state, { payload: locations }) => ({
      ...state,
      idsByLocation: customById(locations, [])
    }),
    [Actions.RECEIVE_REALIZATIONS]: (state, { payload: { locationId, realizations } }) => ({
      ...state,
      allById: {
        ...state.allById,
        ...allById(realizations)
      },
      idsByLocation: {
        ...state.idsByLocation,
        [locationId]: _.uniq(allIds(realizations))
      }
    })
  },
  {
    allById: {},
    idsByLocation: {}
  }
)

const overlappings = handleActions(
  {
    [Actions.RECEIVE_LOCATIONS]: (state, { payload: locations }) => ({
      ...state,
      idsByLocation: customById(locations, [])
    }),
    [Actions.RECEIVE_REALIZATION_OVERLAPPINGS]: (state, { payload: { locationId, overlappings } }) => ({
      ...state,
      allById: {
        ...state.allById,
        ...allById(overlappings)
      },
      idsByLocation: {
        ...state.idsByLocation,
        [locationId]: _.uniq(allIds(overlappings))
      }
    })
  },
  {
    allById: {},
    idsByLocation: {}
  }
)

const changeovers = handleActions(
  {
    [Actions.RECEIVE_LOCATIONS]: (state, { payload: locations }) => ({
      ...state,
      idsByLocation: customById(locations, [])
    }),
    [Actions.RECEIVE_CHANGEOVERS]: (state, { payload: { locationId, changeovers } }) => ({
      ...state,
      allById: {
        ...state.allById,
        ...allById(changeovers)
      },
      idsByLocation: {
        ...state.idsByLocation,
        [locationId]: _.uniq(allIds(changeovers))
      }
    }),
    [Actions.MARK_CHANGEOVER_URGENT]: (state, { payload: { urgentChangeovers } }) => ({
      ...state,
      allById: {
        ...state.allById,
        ...allById(urgentChangeovers)
      }
    })
  },
  {
    allById: {},
    idsByLocation: {}
  }
)

const DEFAULT_FORM_STATE = { isMulti: false, isActive: false, isLoading: false, type: null, errors: {}, data: {} }
const form = handleActions(
  {
    [Actions.START_INIT]: (state) => DEFAULT_FORM_STATE,
    [Actions.OPEN_FORM]: (state, { payload: { type, data, multi, variant } }) => ({
      ...state,
      isActive: true,
      isMulti: !!multi,
      type,
      variant,
      data: multi ? { [randomId()]: data } : data
    }),
    [Actions.CLOSE_FORM]: (state, { payload: formId }) => {
      if (formId === true) {
        return DEFAULT_FORM_STATE
      }

      if (state.isMulti) {
        const newData = _.omit(state.data, [formId])
        const newErrors = _.omit(state.errors, [formId])

        if (Object.keys(newData).length) {
          return {
            ...state,
            data: newData,
            errors: newErrors
          }
        } else {
          return DEFAULT_FORM_STATE
        }
      } else {
        return DEFAULT_FORM_STATE
      }
    },
    [Actions.SUBMIT_FORM]: (state) => ({ ...state, isLoading: true }),
    [Actions.ADD_FORM_ERRORS]: (state, { payload: { errors, formId } }) => {
      const isMulti = state.isMulti

      if (isMulti) {
        return {
          ...state,
          isLoading: false,
          errors: {
            ...state.errors,
            [formId]: errors
          }
        }
      } else {
        return { ...state, isLoading: false, errors }
      }
    },
    [Actions.CHANGE_FORM_DATA]: (state, { payload }) => {
      let { formId, ...data } = payload

      if (state.isMulti) {
        if (formId === undefined) {
          formId = Object.keys(state.data)[0]
        }
        return {
          ...state,
          data: {
            ...state.data,
            [formId]: {
              ...(state.data[formId] || {}),
              ...data
            }
          }
        }
      } else {
        return { ...state, data: { ...state.data, ...data } }
      }
    },
    [Actions.ADD_SUBFORM]: (state) => ({
      ...state,
      data: {
        ...state.data,
        [randomId()]: { ..._.omit(state.data[Object.keys(state.data)[0]], OMIT_FOR_SUBFORM) }
      }
    })
  },
  DEFAULT_FORM_STATE
)

const activenesses = handleActions(
  {
    [Actions.RECEIVE_LOCATIONS]: (state, { payload: locations }) => ({
      ...state,
      idsByLocation: customById(locations, [])
    }),
    [Actions.RECEIVE_ACTIVENESSES]: (state, { payload: activenesses }) => ({
      ...state,
      allById: allById(activenesses),
      idsByLocation: {
        ...state.idsByLocation,
        ...idsByCustom(activenesses, 'locationId')
      }
    }),
    [Actions.RECEIVE_ACTIVENESS]: (state, { payload: activeness }) => ({
      ...state,
      allById: { ...state.allById, [activeness.id]: activeness },
      idsByLocation: {
        ...state.idsByLocation,
        [activeness.locationId]: [...state.idsByLocation[activeness.locationId], activeness.id]
      },
      errors: {}
    }),
    [Actions.DELETE_FINISHED_ACTIVENESS]: (state, { payload: activeness }) => ({
      ...state,
      idsByLocation: {
        ...state.idsByLocation,
        [activeness.locationId]: _.without(state.idsByLocation[activeness.locationId], activeness.id)
      }
    }),
    [Actions.ADD_ACTIVENESS_ERRORS]: (state, { payload: errors }) => ({
      ...state,
      errors
    })
  },
  { allById: {}, idsByLocation: {}, errors: {} }
)

const permissions = handleActions(
  {
    [Actions.RECEIVE_PERMISSIONS]: (state, { payload: permissions }) => ({
      planning: permissions.planningApp,
      realization: permissions.realizationApp,
      formreiniger: permissions.formreinigerApp
    })
  },
  {
    planning: { read: false, write: false },
    realization: { read: false, write: false },
    formreiniger: { read: false, write: false }
  }
)

const predefinedRemarks = handleActions(
  {
    [Actions.RECEIVE_PREDEFINED_REMARKS]: receiveCollection
  },
  { allById: {}, allIds: [] }
)

const qualityControllers = handleActions(
  {
    [Actions.RECEIVE_QUALITY_CONTROLLERS]: receiveCollection
  },
  { allById: {}, allIds: [] }
)

const userGroups = handleActions(
  {
    [Actions.RECEIVE_USER_GROUPS]: receiveCollection
  },
  { allById: {}, allIds: [] }
)

const components = handleActions(
  {
    [Actions.RECEIVE_COMPONENTS]: (state, { payload }) => {
      return ({
        ...receiveCollection(state, { payload }),
        idsByProduct: idsByCustomCollection(payload, 'productIds')
      })
    }
  },
  { allById: {}, allIds: [] }
)

const barcodeModal = handleActions(
  {
    [Actions.SHOW_PRODUCT_BARCODE]: (state, { payload: { productId, type } }) => ({
      productId,
      type
    }),
    [Actions.HIDE_PRODUCT_BARCODE]: () => ({
      productId: null,
      type: null
    })
  },
  { productId: null, type: null }
)

const breakdowns = handleActions(
  {
    [Actions.RECEIVE_LOCATIONS]: (state, { payload: locations }) => ({
      ...state,
      idsByLocation: customById(locations, [])
    }),
    [Actions.RECEIVE_BREAKDOWNS]: (state, { payload: breakdowns }) => ({
      ...state,
      allById: allById(breakdowns),
      idsByLocation: {
        ...state.idsByLocation,
        ...idsByCustom(breakdowns, 'locationId')
      }
    }),
    [Actions.RECEIVE_BREAKDOWN]: (state, { payload: breakdown }) => ({
      ...state,
      allById: { ...state.allById, [breakdown.id]: breakdown },
      idsByLocation: {
        ...state.idsByLocation,
        [breakdown.locationId]: [...state.idsByLocation[breakdown.locationId], breakdown.id]
      }
    }),
    [Actions.DELETE_FINISHED_BREAKDOWN]: (state, { payload: breakdown }) => ({
      ...state,
      idsByLocation: {
        ...state.idsByLocation,
        [breakdown.locationId]: _.without(state.idsByLocation[breakdown.locationId], breakdown.id)
      }
    })
  },
  { allById: {}, idsByLocation: {} }
)

const scraps = handleActions(
  {
    [Actions.RECEIVE_LOCATIONS]: (state, { payload: locations }) => ({
      ...state,
      idsByLocation: customById(locations, []),
      percentageByLocation: customById(locations, [])
    }),
    [Actions.RECEIVE_SCRAPS]: (state, { payload: { locationId, scraps, percentage } }) => ({
      ...state,
      allById: {
        ...state.allById,
        ...allById(scraps)
      },
      idsByLocation: {
        ...state.idsByLocation,
        [locationId]: _.uniq(allIds(scraps))
      },
      percentageByLocation: {
        ...state.percentageByLocation,
        [locationId]: percentage
      }
    })
  },
  {
    allById: {},
    idsByLocation: {},
    percentageByLocation: []
  }
)

const realizationSummaries = handleActions(
  {
    [Actions.RECEIVE_LOCATIONS]: (state, { payload: locations }) => ({
      ...state,
      idsByLocation: customById(locations, [])
    }),
    [Actions.RECEIVE_REALIZATION_SUMMARIES]: (state, { payload: { locationId, realizationSummaries } }) => ({
      ...state,
      allById: {
        ...state.allById,
        ...allById(realizationSummaries)
      },
      idsByLocation: {
        ...state.idsByLocation,
        [locationId]: _.uniq(allIds(realizationSummaries))
      }
    })
  },
  {
    allById: {},
    idsByLocation: {}
  }
)

const realizationHourlySummaries = handleActions(
  {
    [Actions.RECEIVE_LOCATIONS]: (state, { payload: locations }) => ({
      ...state,
      idsByLocation: customById(locations, [])
    }),
    [Actions.RECEIVE_REALIZATION_HOURLY_SUMMARIES]: (state, { payload: { locationId, realizationHourlySummaries } }) => ({
      ...state,
      allById: {
        ...state.allById,
        ...allById(realizationHourlySummaries)
      },
      idsByLocation: {
        ...state.idsByLocation,
        [locationId]: _.uniq(allIds(realizationHourlySummaries))
      }
    })
  },
  {
    allById: {},
    idsByLocation: {}
  }
)

const currentUser = handleActions(
  {
    [Actions.RECEIVE_CURRENT_USER]: (_state, { payload }) => payload,
    [combineActions( // eslint-disable-line
      Actions.MARK_SHIFT_BOOK_NOTE_AS_READ_SUCCESS,
      Actions.MARK_ALL_SHIFT_BOOK_NOTES_AS_READ_SUCCESS
    )]: (state, { payload: noteIds }) => ({
      ...state,
      unreadShiftBookNoteIds: (state.unreadShiftBookNoteIds || []).filter(id => !noteIds.includes(id))
    })
  },
  {}
)

const shiftBookNotesInitState = {
  allById: {},
  allIds: [],
  loading: true,
  draft: '',
  editingId: null,
  replyingId: null,
  error: false,
  processing: false,
  rootIds: [],
  idsByTopic: {}
}
const shiftBookNotes = handleActions(
  {
    [combineActions( // eslint-disable-line
      SET_NAVIGATION,
      Actions.SELECT_SINGLE_LOCATION
    )]: () => shiftBookNotesInitState,
    [Actions.RECEIVE_SHIFT_BOOK]: (state, action) => {
      const { allById, allIds, ...restState } = receiveCollection(state, action)

      return {
        ...restState,
        allById,
        allIds,
        rootIds: allIds.filter(id => !allById[id].topicId),
        idsByTopic: idsByCustom(action.payload, 'topicId'),
        loading: false
      }
    },
    [Actions.CHANGE_SHIFT_BOOK_NOTE]: (state, { payload }) => ({
      ...state,
      draft: payload
    }),
    [Actions.SET_SHIFT_BOOK_NOTE_AS_RESOLVED_SUCCESS]: (state, { payload: id }) => ({
      ...state,
      allById: {
        ...state.allById,
        [id]: {
          ...state.allById[id],
          resolved: true
        }
      }
    }),
    [Actions.EDIT_SHIFT_BOOK_NOTE]: (state, { payload: id }) => ({
      ...state,
      editingId: id,
      replyingId: null,
      draft: state.allById[id].body
    }),
    [Actions.CANCEL_EDIT_SHIFT_BOOK_NOTE]: (state) => ({
      ...state,
      editingId: null,
      draft: '',
      error: false
    }),
    [Actions.REPLY_SHIFT_BOOK_NOTE]: (state, { payload: id }) => ({
      ...state,
      editingId: null,
      replyingId: id,
      draft: ''
    }),
    [Actions.CANCEL_REPLY_SHIFT_BOOK_NOTE]: (state) => ({
      ...state,
      replyingId: null,
      draft: '',
      error: false
    }),
    [Actions.SET_ERROR_SHIFT_BOOK]: (state) => ({
      ...state,
      error: true,
      processing: false
    }),
    [Actions.CLEAR_DRAFT_SHIFT_BOOK]: (state) => ({
      ...state,
      draft: '',
      replyingId: null,
      editingId: null,
      processing: false
    }),
    [combineActions( // eslint-disable-line
      Actions.SUBMIT_EDIT_SHIFT_BOOK_NOTE,
      Actions.CREATE_SHIFT_BOOK_NOTE
    )]: (state) => ({
      ...state,
      processing: true
    })
  },
  shiftBookNotesInitState
)

const measurement = handleActions(
  {
    [Actions.RECEIVE_MEASUREMENT]: (state, { payload }) => payload
  },
  null
)

const notDownloadedAttachmentsCount = handleActions(
  {
    [Actions.RECEIVE_NOT_DOWNLOADED_ATTACHMENTS_COUNT]: (_state, { payload }) => payload
  },
  0
)

export default combineReducers({
  config,
  init,
  locations,
  projects,
  products,
  changeovers,
  stoppageTypes,
  scrapTypes,
  navigation,
  schedules,
  realizations,
  activenesses,
  form,
  permissions,
  predefinedRemarks,
  barcodeModal,
  breakdowns,
  scraps,
  realizationSummaries,
  realizationHourlySummaries,
  currentUser,
  shiftBookNotes,
  measurement,
  qualityControllers,
  userGroups,
  components,
  overlappings,
  notDownloadedAttachmentsCount
})
