import { createAction } from 'redux-actions'

const Actions = {
  SET_TOKEN: 'SET_TOKEN',
  SET_MODE: 'SET_MODE',
  START_INIT: 'START_INIT',
  FINISH_INIT: 'FINISH_INIT',
  RECEIVE_LOCATIONS: 'RECEIVE_LOCATIONS',
  SELECT_LOCATION: 'SELECT_LOCATION',
  SELECT_SINGLE_LOCATION: 'SELECT_SINGLE_LOCATION',
  UNSELECT_LOCATION: 'UNSELECT_LOCATION',
  RECEIVE_PROJECTS: 'RECEIVE_PROJECTS',
  RECEIVE_PRODUCTS: 'RECEIVE_PRODUCTS',
  REQUEST_CHANGEOVERS: 'REQUEST_CHANGEOVERS',
  RECEIVE_CHANGEOVERS: 'RECEIVE_CHANGEOVERS',
  MARK_CHANGEOVER_URGENT: 'MARK_CHANGEOVER_URGENT',
  CREATE_CHANGEOVER_CHANGE: 'CREATE_CHANGEOVER_CHANGE',
  DESTROY_CHANGEOVER_CHANGE: 'DESTROY_CHANGEOVER_CHANGE',
  REQUEST_SCHEDULES: 'REQUEST_SCHEDULES',
  RECEIVE_SCHEDULES: 'RECEIVE_SCHEDULES',
  EDIT_SCHEDULE: 'EDIT_SCHEDULE',
  DESTROY_SCHEDULE: 'DESTROY_SCHEDULE',
  REQUEST_REALIZATIONS: 'REQUEST_REALIZATIONS',
  RECEIVE_REALIZATIONS: 'RECEIVE_REALIZATIONS',
  RECEIVE_REALIZATION_OVERLAPPINGS: 'RECEIVE_REALIZATION_OVERLAPPINGS',
  EDIT_REALIZATION: 'EDIT_REALIZATION',
  DESTROY_REALIZATION: 'DESTROY_REALIZATION',
  OPEN_NEW_FORM: 'OPEN_NEW_FORM',
  OPEN_FORM: 'OPEN_FORM',
  CLOSE_FORM: 'CLOSE_FORM',
  SUBMIT_FORM: 'SUBMIT_FORM',
  ADD_FORM_ERRORS: 'ADD_FORM_ERRORS',
  CHANGE_FORM_DATA: 'CHANGE_FORM_DATA',
  ADD_SUBFORM: 'ADD_SUBFORM',
  RECEIVE_STOPPAGE_TYPES: 'RECEIVE_STOPPAGE_TYPES',
  RECEIVE_SCRAP_TYPES: 'RECEIVE_SCRAP_TYPES',
  RECEIVE_ACTIVENESSES: 'RECEIVE_ACTIVENESSES',
  RECEIVE_ACTIVENESS: 'RECEIVE_ACTIVENESS',
  START_ACTIVENESS: 'START_ACTIVENESS',
  ADD_ACTIVENESS_ERRORS: 'ADD_ACTIVENESS_ERRORS',
  FINISH_ACTIVENESS: 'FINISH_ACTIVENESS',
  DELETE_FINISHED_ACTIVENESS: 'DELETE_FINISHED_ACTIVENESS',
  RECEIVE_PERMISSIONS: 'RECEIVE_PERMISSIONS',
  SELECT_TIMELINE_BLOCK: 'SELECT_TIMELINE_BLOCK',
  RECEIVE_PREDEFINED_REMARKS: 'RECEIVE_PREDEFINED_REMARKS',
  SHOW_PRODUCT_BARCODE: 'SHOW_PRODUCT_BARCODE',
  HIDE_PRODUCT_BARCODE: 'HIDE_PRODUCT_BARCODE',
  UPDATE_PLAN_CHANGED: 'UPDATE_PLAN_CHANGED',
  RECEIVE_BREAKDOWNS: 'RECEIVE_BREAKDOWNS',
  RECEIVE_BREAKDOWN: 'RECEIVE_BREAKDOWN',
  START_BREAKDOWN: 'START_BREAKDOWN',
  FINISH_BREAKDOWN: 'FINISH_BREAKDOWN',
  DELETE_FINISHED_BREAKDOWN: 'DELETE_FINISHED_BREAKDOWN',
  REQUEST_SCRAPS: 'REQUEST_SCRAPS',
  RECEIVE_SCRAPS: 'RECEIVE_SCRAPS',
  EDIT_SCRAP: 'EDIT_SCRAP',
  DESTROY_SCRAP: 'DESTROY_SCRAP',
  REQUEST_REALIZATION_SUMMARIES: 'REQUEST_REALIZATION_SUMMARIES',
  RECEIVE_REALIZATION_SUMMARIES: 'RECEIVE_REALIZATION_SUMMARIES',
  RECEIVE_REALIZATION_HOURLY_SUMMARIES: 'RECEIVE_REALIZATION_HOURLY_SUMMARIES',
  RECEIVE_CURRENT_USER: 'RECEIVE_CURRENT_USER',
  OPEN_SHIFT_BOOK: 'OPEN_SHIFT_BOOK',
  RECEIVE_SHIFT_BOOK: 'RECEIVE_SHIFT_BOOK',
  CHANGE_SHIFT_BOOK_NOTE: 'CHANGE_SHIFT_BOOK_NOTE',
  CREATE_SHIFT_BOOK_NOTE: 'CREATE_SHIFT_BOOK_NOTE',
  EDIT_SHIFT_BOOK_NOTE: 'EDIT_SHIFT_BOOK_NOTE',
  REPLY_SHIFT_BOOK_NOTE: 'REPLY_SHIFT_BOOK_NOTE',
  SUBMIT_EDIT_SHIFT_BOOK_NOTE: 'SUBMIT_EDIT_SHIFT_BOOK_NOTE',
  CANCEL_EDIT_SHIFT_BOOK_NOTE: 'CANCEL_EDIT_SHIFT_BOOK_NOTE',
  SUBMIT_REPLY_SHIFT_BOOK_NOTE: 'SUBMIT_REPLY_SHIFT_BOOK_NOTE',
  CANCEL_REPLY_SHIFT_BOOK_NOTE: 'CANCEL_REPLY_SHIFT_BOOK_NOTE',
  DESTROY_SHIFT_BOOK_NOTE: 'DESTROY_SHIFT_BOOK_NOTE',
  SET_ERROR_SHIFT_BOOK: 'SET_ERROR_SHIFT_BOOK',
  CLEAR_DRAFT_SHIFT_BOOK: 'CLEAR_DRAFT_SHIFT_BOOK',
  MARK_SHIFT_BOOK_NOTE_AS_READ: 'MARK_SHIFT_BOOK_NOTE_AS_READ',
  MARK_SHIFT_BOOK_NOTE_AS_READ_SUCCESS: 'MARK_SHIFT_BOOK_NOTE_AS_READ_SUCCESS',
  MARK_ALL_SHIFT_BOOK_NOTES_AS_READ: 'MARK_ALL_SHIFT_BOOK_NOTES_AS_READ',
  MARK_ALL_SHIFT_BOOK_NOTES_AS_READ_SUCCESS: 'MARK_ALL_SHIFT_BOOK_NOTES_AS_READ_SUCCESS',
  SET_SHIFT_BOOK_NOTE_AS_RESOLVED: 'SET_SHIFT_BOOK_NOTE_AS_RESOLVED',
  SET_SHIFT_BOOK_NOTE_AS_RESOLVED_SUCCESS: 'SET_SHIFT_BOOK_NOTE_AS_RESOLVED_SUCCESS',
  SHOW_ALL_LOCATIONS: 'SHOW_ALL_LOCATIONS',
  HIDE_ALL_LOCATIONS: 'HIDE_ALL_LOCATIONS',
  REQUEST_MEASUREMENT: 'REQUEST_MEASUREMENT',
  RECEIVE_MEASUREMENT: 'RECEIVE_MEASUREMENT',
  CONFIRM_MEASUREMENT: 'CONFIRM_MEASUREMENT',
  RECEIVE_QUALITY_CONTROLLERS: 'RECEIVE_QUALITY_CONTROLLERS',
  RECEIVE_USER_GROUPS: 'RECEIVE_USER_GROUPS',
  RECEIVE_COMPONENTS: 'RECEIVE_COMPONENTS',
  RECEIVE_NOT_DOWNLOADED_ATTACHMENTS_COUNT: 'RECEIVE_NOT_DOWNLOADED_ATTACHMENTS_COUNT'
}

export default Actions

export const setToken = createAction(Actions.SET_TOKEN)
export const setMode = createAction(Actions.SET_MODE)
export const startInit = createAction(Actions.START_INIT)
export const finishInit = createAction(Actions.FINISH_INIT)
export const receiveLocations = createAction(Actions.RECEIVE_LOCATIONS)
export const selectLocation = createAction(Actions.SELECT_LOCATION)
export const selectSingleLocation = createAction(Actions.SELECT_SINGLE_LOCATION)
export const unselectLocation = createAction(Actions.UNSELECT_LOCATION)
export const receiveProjects = createAction(Actions.RECEIVE_PROJECTS)
export const receiveProducts = createAction(Actions.RECEIVE_PRODUCTS)
export const requestChangeovers = createAction(Actions.REQUEST_CHANGEOVERS)
export const receiveChangeovers = createAction(Actions.RECEIVE_CHANGEOVERS)
export const markChangeoverUrgent = createAction(Actions.MARK_CHANGEOVER_URGENT)
export const createChangeoverChange = createAction(Actions.CREATE_CHANGEOVER_CHANGE)
export const destroyChangeoverChange = createAction(Actions.DESTROY_CHANGEOVER_CHANGE)
export const requestSchedules = createAction(Actions.REQUEST_SCHEDULES)
export const receiveSchedules = createAction(Actions.RECEIVE_SCHEDULES)
export const editSchedule = createAction(Actions.EDIT_SCHEDULE)
export const destroySchedule = createAction(Actions.DESTROY_SCHEDULE)
export const requestRealizations = createAction(Actions.REQUEST_REALIZATIONS)
export const receiveRealizations = createAction(Actions.RECEIVE_REALIZATIONS)
export const receiveRealizationOverlappings = createAction(Actions.RECEIVE_REALIZATION_OVERLAPPINGS)
export const editRealization = createAction(Actions.EDIT_REALIZATION)
export const destroyRealization = createAction(Actions.DESTROY_REALIZATION)
export const openForm = createAction(Actions.OPEN_FORM, (type, data, multi, variant) => ({ type, data, multi, variant }))
export const openNewForm = createAction(Actions.OPEN_NEW_FORM, (type, data, multi, variant) => ({ type, data, multi, variant }))
export const closeForm = createAction(Actions.CLOSE_FORM)
export const submitForm = createAction(
  Actions.SUBMIT_FORM,
  (data, successfulMessage) => ({ data, successfulMessage })
)
export const addFormErrors = createAction(Actions.ADD_FORM_ERRORS, (errors, formId) => ({
  errors,
  formId
}))
export const changeFormData = createAction(Actions.CHANGE_FORM_DATA, (data, formId) => ({
  ...data,
  formId
}))
export const addSubform = createAction(Actions.ADD_SUBFORM)
export const receiveStoppageTypes = createAction(Actions.RECEIVE_STOPPAGE_TYPES)
export const receiveScrapTypes = createAction(Actions.RECEIVE_SCRAP_TYPES)
export const receiveActivenesses = createAction(Actions.RECEIVE_ACTIVENESSES)
export const receiveActiveness = createAction(Actions.RECEIVE_ACTIVENESS)
export const startActiveness = createAction(Actions.START_ACTIVENESS)
export const addActivenessErrors = createAction(Actions.ADD_ACTIVENESS_ERRORS)
export const finishActiveness = createAction(Actions.FINISH_ACTIVENESS)
export const deleteFinishedActiveness = createAction(Actions.DELETE_FINISHED_ACTIVENESS)
export const receivePermissions = createAction(Actions.RECEIVE_PERMISSIONS)
export const selectTimelineBlock = createAction(Actions.SELECT_TIMELINE_BLOCK)
export const receivePredefinedRemarks = createAction(Actions.RECEIVE_PREDEFINED_REMARKS)
export const showProductBarcode = createAction(Actions.SHOW_PRODUCT_BARCODE)
export const hideProductBarcode = createAction(Actions.HIDE_PRODUCT_BARCODE)
export const updatePlanChanged = createAction(Actions.UPDATE_PLAN_CHANGED)
export const receiveBreakdowns = createAction(Actions.RECEIVE_BREAKDOWNS)
export const receiveBreakdown = createAction(Actions.RECEIVE_BREAKDOWN)
export const deleteFinishedBreakdown = createAction(Actions.DELETE_FINISHED_BREAKDOWN)
export const requestScraps = createAction(Actions.REQUEST_SCRAPS)
export const receiveScraps = createAction(Actions.RECEIVE_SCRAPS)
export const editScrap = createAction(Actions.EDIT_SCRAP)
export const destroyScrap = createAction(Actions.DESTROY_SCRAP)
export const requestRealizationSummaries = createAction(Actions.REQUEST_REALIZATION_SUMMARIES)
export const receiveRealizationSummaries = createAction(Actions.RECEIVE_REALIZATION_SUMMARIES)
export const receiveRealizationHourlySummaries = createAction(Actions.RECEIVE_REALIZATION_HOURLY_SUMMARIES)
export const receiveCurrentUser = createAction(Actions.RECEIVE_CURRENT_USER)
export const openShiftBook = createAction(Actions.OPEN_SHIFT_BOOK)
export const receiveShiftBook = createAction(Actions.RECEIVE_SHIFT_BOOK)
export const changeShiftBookNote = createAction(Actions.CHANGE_SHIFT_BOOK_NOTE)
export const createShiftBookNote = createAction(Actions.CREATE_SHIFT_BOOK_NOTE)
export const editShiftBookNote = createAction(Actions.EDIT_SHIFT_BOOK_NOTE)
export const replyShiftBookNote = createAction(Actions.REPLY_SHIFT_BOOK_NOTE)
export const submitEditShiftBookNote = createAction(Actions.SUBMIT_EDIT_SHIFT_BOOK_NOTE)
export const cancelEditShiftBookNote = createAction(Actions.CANCEL_EDIT_SHIFT_BOOK_NOTE)
export const submitReplyShiftBookNote = createAction(Actions.SUBMIT_REPLY_SHIFT_BOOK_NOTE)
export const cancelReplyShiftBookNote = createAction(Actions.CANCEL_REPLY_SHIFT_BOOK_NOTE)
export const destroyShiftBookNote = createAction(Actions.DESTROY_SHIFT_BOOK_NOTE)
export const setErrorShiftBook = createAction(Actions.SET_ERROR_SHIFT_BOOK)
export const clearDraftShiftBook = createAction(Actions.CLEAR_DRAFT_SHIFT_BOOK)
export const markShiftBookNoteAsRead = createAction(Actions.MARK_SHIFT_BOOK_NOTE_AS_READ)
export const markShiftBookNoteAsReadSuccess = createAction(Actions.MARK_SHIFT_BOOK_NOTE_AS_READ_SUCCESS)
export const markAllShiftBookNotesAsRead = createAction(Actions.MARK_ALL_SHIFT_BOOK_NOTES_AS_READ)
export const markAllShiftBookNotesAsReadSuccess =
  createAction(Actions.MARK_ALL_SHIFT_BOOK_NOTES_AS_READ_SUCCESS)
export const setShiftBookNoteAsResolved = createAction(Actions.SET_SHIFT_BOOK_NOTE_AS_RESOLVED)
export const setShiftBookNoteAsResolvedSuccess = createAction(Actions.SET_SHIFT_BOOK_NOTE_AS_RESOLVED_SUCCESS)
export const showAllLocations = createAction(Actions.SHOW_ALL_LOCATIONS)
export const hideAllLocations = createAction(Actions.HIDE_ALL_LOCATIONS)
export const requestMeasurement = createAction(Actions.REQUEST_MEASUREMENT)
export const receiveMeasurement = createAction(Actions.RECEIVE_MEASUREMENT)
export const confirmMeasurement = createAction(Actions.CONFIRM_MEASUREMENT)
export const receiveQualityControllers = createAction(Actions.RECEIVE_QUALITY_CONTROLLERS)
export const receiveUserGroups = createAction(Actions.RECEIVE_USER_GROUPS)
export const receiveComponents = createAction(Actions.RECEIVE_COMPONENTS)
export const receiveNotDownloadedAttachmentsCount = createAction(Actions.RECEIVE_NOT_DOWNLOADED_ATTACHMENTS_COUNT)
